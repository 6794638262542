const { get, event } = require("jquery");
let routeArrLonLat = [];
let markerIconArr = [];
//console.log('routeFeature', routeFeature);
$("#phone2").mask("+7 (999) 999-9999");
$("#phone").mask("+7 (999) 999-9999");
localStorage.setItem("pr", 0);

function reloadCaptcha(captchaNode) {
    $.ajax({
        type: "GET",
        url: "/reload-captcha",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function (data) {
            $(captchaNode).html(data.captcha);
            //console.log(data.captcha);
            //console.log(captchaNode);
        },
    });
}
$("#car__options__btn").click(function (e) {
    $("#car__options__list").toggleClass("car__options__list__active");
    $("#car__options__btn").toggleClass("car__options__btn__active");
    console.log(e.target);
});

function reloadCaptchaMainForm() {
    $.ajax({
        type: "GET",
        url: "/reload-captcha",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function (data) {
            $("#captcha__img__form").html(data.captcha);
            //console.log(data.captcha);
        },
    });
}

/* добавить заезд */
var addArrivalBtn = document.getElementById("btn__arrival");

function addArrival() {
    var wrapListItem = document.getElementById("sortable__wrap");
    var divRowArrival = document.createElement("div");
    var divArrival = document.createElement("div");
    var iconArrival = document.createElement("i");
    var inputArrival = document.createElement("input");

    divRowArrival.className = "sortable__row";
    divArrival.className = "sortable__item";
    iconArrival.className = "fas fa-arrows-alt-v";
    inputArrival.className = "form_input to ac_input form_input__sortable";
    inputArrival.type = "text";
    inputArrival.placeholder = "Куда";

    wrapListItem.appendChild(divRowArrival);
    divRowArrival.appendChild(divArrival);
    divArrival.appendChild(iconArrival);
    divRowArrival.appendChild(inputArrival);
}

function addArrivalPlaceholder() {
    let arrivalInputs = document.querySelectorAll(".form_input__sortable");
    //$(arrivalInputs[0]).attr("placeholder", "Откуда");
    //console.log('arrivalInputs', arrivalInputs);
    for (let index = 0; index < arrivalInputs.length; index++) {
        const element = arrivalInputs[index];
        if (index == 0) {
            $(element).attr("placeholder", "Откуда");
        } else {
            $(element).attr("placeholder", "Куда");
        }
    }
}
for (let index = 0; index < 2; index++) {
    addArrival();
    addArrivalPlaceholder();
}
let counterArrival = 0;
$(addArrivalBtn).click(function (e) {
    if (counterArrival < 4) {
        addArrival();
        counterArrival++;
        //console.log(counterArrival);
    } else {
        $(".arrival__click__error").addClass("arrival__click__error-active");
        addArrivalPlaceholder();
    }
});
// сортировка адресов
$("#sortable__wrap").sortable({
    handle: ".sortable__item",
    update: function (event, ui) {
        addArrivalPlaceholder();
    },
});

// popup login
$("#btn__login").click(function (e) {
    $("#popup__login").addClass("popup__active");
});

$("#login__close_popup").click(function (e) {
    $("#popup__login").removeClass("popup__active");
    //let captchaNode = getElementById('captcha__img');
    let captchaNode = "#captcha__img";
    reloadCaptcha(captchaNode);
});
window.onclick = function (e) {
    let popupLogin = document.getElementById("popup__login");
    let cancelOrderConfirm = document.getElementById("cancel__order__confirm");
    if (e.target == popupLogin) {
        $("#popup__login").removeClass("popup__active");
    }
    if (e.target == cancelOrderConfirm) {
        $("#cancel__order__confirm").removeClass("popup__active");
    }
};
//получить пароль меняем форму
$("#getpass__form").click(function () {
    $(".popup__form__row-captcha").removeClass("el__none");
    $("#getpass").removeClass("el__none");
    $("#form__block__pass").addClass("el__none");
    $("#form__btn__login").addClass("el__none");
});

//обновление капчи
$("#reload__captcha").click(function () {
    //let captchaNode = getElementById('captcha__img');
    let captchaNode = "#captcha__img";
    reloadCaptcha(captchaNode);
});
// ---обновление капчи
/* $('#reload__captcha__form').click(function () {
   let captchaNode = '#captcha__img__form';
   reloadCaptcha(captchaNode);
}); */

let info;
let z;

function getCarOptionsList(id) {
    $.ajax({
        type: "GET",
        url: "/show_car_options",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            id: id,
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            $("#car__options__list").html(data);
            //console.log(data)
        },
    });
}

function getCitiesAjax() {
    let id = $("#inputState").val();

    $.ajax({
        type: "GET",
        url: "/get_cities",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            id: id,
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            info = data;
            let default_lat = info.thisUnits.default_lat;
            let default_lon = info.thisUnits.default_lon;
            let lipa = [default_lat, default_lon];
            //console.log(default_lon)
            //console.log(default_lat)
        },
    });

    getCarOptionsList(id);
}
getCitiesAjax();

function howInfoNav() {
    let id = $("#inputState").val();
    $.ajax({
        type: "GET",
        url: "/get-info-nav",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            id: id,
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            $("#logo__link").html(data[0]);
            $("#phone__nav").html('<i class="fas fa-phone"></i>' + data[1]);
        },
    });
}
howInfoNav();
//getDistance
let servArr = [];

function getDistanceAjax() {
    let allAdr = document.querySelectorAll(".form_input__sortable");
    let to = [];
    let q = "";
    let lonlat = [];
    for (let index = 0; index < allAdr.length; index++) {
        const element = allAdr[index];
        if ($(element).attr("data-hiden")) {
            q = $(element).attr("data-hiden") + ", " + element.value;
        } else {
            q = element.value;
        }
        to.push(q);
        //console.log('dis-to', to)
        if (element.getAttribute("data-lon") != null) {
            lonlat.push(
                element.getAttribute("data-lon") +
                    ";" +
                    element.getAttribute("data-lat")
            );
        }
    }
    let city_id = $("#inputState").val();

    let calendar = $("#calendar").val();

    let minute = "0";
    let hour = "0";
    let day = "0";
    let month = "0";
    let year = "0";
    if (calendar == "") {
        //console.log('time999');
    } else {
        let calendar = $("#calendar").val();
        let calendarArray = calendar.split(" ");
        let dayArr = calendarArray[0].split(".");
        let timeArr = calendarArray[1].split(":");

        minute = timeArr[1];
        hour = timeArr[0];
        day = dayArr[0];
        month = dayArr[1];
        year = dayArr[2];
    }

    if (lonlat.length > 1) {
        $.ajax({
            type: "GET",
            url: "/get-distance",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                city_id: city_id,
                to: to,
                lonlat: lonlat,
                year: year,
                month: month,
                day: day,
                hour: hour,
                minute: minute,
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            },
            success: function (data) {
                localStorage.setItem("pr", 0);
                if (data == "auth_error") {
                    console.log("ошибка - auth_error");
                    return;
                }
                if (data == "Path not found") {
                    console.log("ошибка - Path not found");
                    return;
                }

                let thisEll = document.getElementById("content__page__menu");
                thisEll.innerHTML = "";
                $("#content__container").removeClass("page__active");

                //console.log('data dis',data);
                let dataPars = [];
                if (data) {
                    dataPars = JSON.parse(data);
                }

                // console.log('dataPars', Object.entries(dataPars));
                let dataArr = Object.entries(dataPars);
                let showPriceRow = document.getElementById("show__price");
                let showPriceWrap =
                    document.getElementById("show__price__wrap");
                let showPriceDist =
                    document.getElementById("show__price__dist");
                let showPriceRur = document.getElementById("show__price__rur");
                //console.log('dataArr', dataArr);
                servArr = dataArr;
                let placesArr = [];
                let routeArr = [];
                for (let i = 0; i < dataArr.length; i++) {
                    let element = dataArr[i];

                    if (element[0] == "distance") {
                        if (element[1] != 0) {
                            $(showPriceRow).addClass(
                                "order__form__row show__price"
                            );
                            $(showPriceWrap).addClass("show__price__wrap");

                            showPriceDist.innerHTML = element[1] + " км";
                        }
                    }
                    if (element[0] == "fix_price") {
                        if (element[1] != 0) {
                            $(showPriceRow).addClass(
                                "order__form__row show__price"
                            );
                            $(showPriceWrap).addClass("show__price__wrap");
                            showPriceRur.innerHTML = element[1] + " &#8381;";
                            localStorage.setItem("pr", element[1]);
                            localStorage.setItem("pr_type", "fix_price");
                            //console.log(dataArr)
                            addPriseCarOpt();
                        }
                    } else if (element[0] == "pre_price") {
                        if (element[1] != 0) {
                            $(showPriceRow).addClass(
                                "order__form__row show__price"
                            );
                            $(showPriceWrap).addClass("show__price__wrap");
                            showPriceRur.innerHTML =
                                "поездка от " + element[1] + " &#8381;";
                            localStorage.setItem("pr", element[1]);
                            localStorage.setItem("pr_type", "pre_price");
                            addPriseCarOpt();
                        }
                    }
                    // if (element[0] == 'pre_price') {
                    //    if (element[1] != 0) {
                    //       $(showPriceRow).addClass('order__form__row show__price');
                    //       $(showPriceWrap).addClass('show__price__wrap');
                    //       showPriceRur.innerHTML = 'поездка от ' + element[1] + ' &#8381;';
                    //    }
                    // }
                    if (element[0] == "places") {
                        placesArr = element[1];
                    }
                    if (element[0] == "nodes") {
                        routeArr = element[1];
                    }
                }
                // добавляем маркеры
                if (placesArr.length > 1) {
                    markerIconArr = [];
                    for (let i = 0; i < placesArr.length - 1; i++) {
                        let places = placesArr[i];
                        let lonLat = [places.lon, places.lat];
                        let newGeom = new ol.geom.Point(
                            ol.proj.fromLonLat(lonLat)
                        );
                        let markerIconFeature = new ol.Feature({
                            geometry: newGeom,
                        });
                        let s = i + 1;
                        let textStyle = new ol.style.Style({
                            image: new ol.style.Icon({
                                anchor: [0.5, 46],
                                anchorXUnits: "fraction",
                                anchorYUnits: "pixels",
                                src: "img/place.png",
                            }),
                            text: new ol.style.Text({
                                text: s + "",
                                scale: [1.5, 1.5],
                                offsetY: "-28",
                            }),
                        });
                        markerIconFeature.setStyle(textStyle);
                        markerIconArr.push(markerIconFeature);
                    }
                    //рисуем маршрут
                    routeArrLonLat = [];
                    for (let i = 0; i < routeArr.length - 1; i++) {
                        let routeElem = [routeArr[i].lon, routeArr[i].lat];
                        let routeElemProj = ol.proj.fromLonLat(routeElem);
                        routeArrLonLat.push(routeElemProj);
                    }
                    // console.log('routeArrLonLat', routeArrLonLat);
                    addRoute(routeArrLonLat);
                    addMarkers(markerIconArr);
                }
            } /* success: function (data) */,
        });
    }
}
//---getDistance
$("#calendar").blur(function () {
    getDistanceAjax();
});
/* карта */
let map;
let mainLayer;
mainLayer = new ol.layer.Tile({
    source: new ol.source.OSM("noname", [
        "https://tiles.taxomet.ru/1/osm-map/${z}/${x}/${y}.png",
    ]),
});
// получаем координаты центра и зум
function getLonLat() {
    let id = $("#inputState").val();
    //let id = 80;

    let result = $.ajax({
        type: "GET",
        url: "/getlonlat",
        async: false,
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            id: id,
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            //console.log('getLonLat', data)
        },
    });
    //if ()
    let resLonLat = $.parseJSON(result.responseText);
    if (resLonLat.message) {
        return [37.2, 55.64, 10];
    } else {
        return resLonLat;
    }
    //console.log('resLonLat', resLonLat.message)
}
//создаем карту с координатами по умолчаню
function initMap() {
    let dataArr = getLonLat();
    let defaultLonLat = [dataArr[0], dataArr[1]];
    let defaultZoom = dataArr[2];
    map = new ol.Map({
        target: "osm",
        layers: [mainLayer],
        view: new ol.View({
            center: ol.proj.fromLonLat(defaultLonLat),
            zoom: defaultZoom,
        }),
    });
}
initMap();
//добавим маршрут
let routeGeometry = "";
const routeFeature = new ol.Feature({
    geometry: routeGeometry,
});

const routeStyle = new ol.style.Style({
    stroke: new ol.style.Stroke({
        width: 6,
        color: [0, 155, 255, 0.7],
    }),
});
const routeLayer = new ol.layer.Vector({
    source: new ol.source.Vector({
        features: [routeFeature],
    }),
    style: routeStyle,
    name: "routeLayer",
});

map.addLayer(routeLayer);

function addRoute(routeArrLonLat) {
    routeGeometry = new ol.geom.LineString(routeArrLonLat);
    routeFeature.setGeometry(routeGeometry);
    let nView = new ol.View();
    nView.fit(routeGeometry, {
        padding: [50, 50, 50, 500],
        minResolution: 5,
        maxZoom: 10,
        size: [500, 500],
    });
    map.setView(nView);
    //console.log('routeLayer.getDataExtent()',routeLayer.zoom)
}
//добавить маркеры
let markerIconGeometry = "";
let markerIconFeature = new ol.Feature({
    geometry: markerIconGeometry,
});

const markerIconStyle = new ol.style.Style({
    image: new ol.style.Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: "img/place.png",
    }),
});

let markerSource = new ol.source.Vector({
    features: markerIconArr,
});
let markerLayer = new ol.layer.Vector({
    source: markerSource,
    style: markerIconStyle,
});

map.addLayer(markerLayer);

function addMarkers(markerIconArr) {
    markerSource = new ol.source.Vector({
        features: markerIconArr,
    });
    markerLayer.setSource(markerSource);
}
/* ---карта */
//показываем странички главного меню
function showPage(route, pid) {
    $.ajax({
        type: "GET",
        url: route,
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            pid: pid,
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            $("#content__container").addClass("page__active");
            $("#content__page__menu").html(data);
            //console.log('страница', data)
        },
    });
}

$("#nav__menu").on("click", function (event) {
    let route = "/page";
    if (event.target.className === "tabs__link") {
        let thisLinc = event.target;
        let pid = thisLinc.getAttribute("pid");

        //console.log('thisLinc', thisLinc);

        showPage(route, pid);
        $("#burger").removeClass("burger__active");
        $("#nav__menu").removeClass("nav__menu__active");

        let allLink = document.querySelectorAll(".tabs__link");

        for (let index = 0; index < allLink.length; index++) {
            const element = allLink[index];
            $(element).removeClass("tabs__link__active");
        }

        $(thisLinc).addClass("tabs__link__active");
    }
});
// ---показываем странички главного меню
// ---показываем страничкe Политика конфиденциальности
$("#politika_konfidencialnosti").click(function () {
    let route = "/page";
    let thisLinc = document.getElementById("politika_konfidencialnosti");
    let pid = thisLinc.getAttribute("pid");
    //console.log('pid', pid);
    showPage(route, pid);
});
// ---показываем страничкe Политика конфиденциальности
// чистим показанные страницы
$(".front__content__wrap").on("click", function (event) {
    if (event.target.id === "icon__close__page") {
        let thisEll = document.getElementById("content__page__menu");
        thisEll.innerHTML = "";
        $("#content__container").removeClass("page__active");
        location.hash = "";
    }
});
// ---чистим показанные страницы
//показываем историю заказов
function showOrderHistory(route) {
    $.ajax({
        type: "GET",
        url: route,
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        /*  data: {
             phone: phone
         }, */
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        success: function (data) {
            $("#content__container").addClass("page__active");
            $("#content__page__menu").html(data);
            //setInterval(updateTimer, 1000);
            // console.log('заказы', data)
        },
    });
}
$("#order__history").click(function (e) {
    let route = "/order-history";
    showOrderHistory(route);
});
// ---показываем историю заказов
// показываем текущий заказ
$(".front__page__wrap").on("click", function (event) {
    //$('#current__orders').click(function (e) {
    if (event.target.id === "current__orders") {
        let route = "/current-orders";
        showOrderHistory(route);
    }
});
// ---показываем текущий заказ

$("#inputState").change(function () {
    getCitiesAjax();
    howInfoNav();
});

/* ____________ */
$("form").bind("keypress", function (e) {
    if (e.keyCode == 13) {
        return false;
    }
});

$("#sortable__wrap").on("blur", "input", function () {
    getDistanceAjax();
    // console.log('thisInput----onblur', thisInput);
});
// получить пароль
$("#getpass").click(function () {
    $("#phone2").removeClass("is-invalid");
    $("#error__text").addClass("error__text-block");
    $("#captcha").removeClass("is-invalid");
    $("#error__text__captcha").addClass("error__text-block");
    let phone = $("#phone2").val();
    let captcha = $("#captcha").val();
    phone = phone.replace(/[^0-9+]/g, "");
    let id = $("#inputState :selected").val();

    if (phone === "") {
        $("#phone2").addClass("is-invalid");
        $("#error__text").removeClass("error__text-block");
    } else {
        if (captcha === "") {
            $("#captcha").addClass("is-invalid");
            $("#error__text__captcha").removeClass("error__text-block");
        } else {
            $.ajax({
                type: "get",
                url: "/addusr",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                data: {
                    phone: phone,
                    id: id,
                    captcha: captcha,
                },
                success: function (data) {
                    let dataPars = JSON.parse(data);
                    //console.log('data',dataPars);
                    $("#phone2").removeClass("is-invalid");
                    $("#error__text").addClass("error__text-block");
                    $(".popup__form__row-captcha").addClass("el__none");
                    $("#getpass").addClass("el__none");
                    $("#form__block__pass").removeClass("el__none");
                    $("#form__btn__login").removeClass("el__none");
                    $("#alert__success__sms").removeClass("el__none");
                    let answerSuccess = document.getElementById(
                        "alert__success__sms"
                    );
                    if (dataPars["result"] === "ok") {
                        answerSuccess.innerHTML = "";
                        if (dataPars["mode"] === "voice") {
                            answerSuccess.innerHTML =
                                "Пароль отправлен с помощью звонка";
                        }
                    }
                    if (dataPars["result"] === "error") {
                        answerSuccess.innerHTML = "";
                        answerSuccess.innerHTML =
                            "Ошибка отправки пароля код - " +
                            dataPars["code"] +
                            ". Обратитесь в диспетчерскую";
                        $(answerSuccess).removeClass("alert-success");
                        $(answerSuccess).addClass("alert-danger");
                    }
                },
                error: function (error) {
                    $("#alert__success__sms").removeClass("el__none");
                    let answerSuccess = document.getElementById(
                        "alert__success__sms"
                    );
                    answerSuccess.innerHTML = "";
                    answerSuccess.innerHTML =
                        "Ошибка, что-то пошло не так. Обратитесь в диспетчерскую";
                    $(answerSuccess).removeClass("alert-success");
                    $(answerSuccess).addClass("alert-danger");
                    console.log("error", error);
                    //alert('Не удалось отправить код подтверждения по SMS. Нет связи с сервером');
                },
            });
        }
    }
    let captchaNode = "#captcha__img";
    reloadCaptcha(captchaNode);
});
// ---получить пароль
// выбор даты
$(function () {
    $("#calendar").datepicker({
        minDate: new Date(),
        language: "ru",
        timepicker: true,
        clearButton: true,
    });
});
// ---выбор даты
//добавление нужных строк в получении пароля в форме
let getPassWrap = document.getElementById("get__pass__wrap");
//console.log('getPassWrap', getPassWrap);
function createEnterPassRow() {
    let orderFormRow = document.createElement("div");
    let orderFormRow2 = document.createElement("div");
    let orderFormRow3 = document.createElement("div");
    let orderFormRow4 = document.createElement("div");
    let errPassText = document.createElement("div");
    //let alertSuccessSmsForm = document.createElement('div');
    let getPswBtn = document.createElement("button");
    let getOrderBtn = document.createElement("button");
    let inputOrderPsw = document.createElement("input");
    //alert__success__sms
    orderFormRow.className = "order__form__row";
    orderFormRow2.className = "order__form__row";
    orderFormRow3.className = "order__form__row";
    orderFormRow4.className = "order__form__row";

    errPassText.className = "text-danger text__err";
    errPassText.id = "error__text__pass";

    //alertSuccessSmsForm.className = ('alert alert-success alert-dismissible fade show el__none');
    //alertSuccessSmsForm.id = ('alert__success__sms__form');

    getPswBtn.className = "btn btn-order_psw";
    getPswBtn.type = "button";
    getPswBtn.id = "btn__order__getpsw";
    getPswBtn.textContent = "Получить пароль";

    getOrderBtn.className = "btn btn-order__submit";
    getOrderBtn.type = "submit";
    getOrderBtn.id = "get__order__btn";
    getOrderBtn.textContent = "Заказать такси";

    inputOrderPsw.className = "form_input order_psw order_psw2 input__mw50";
    inputOrderPsw.type = "text";
    inputOrderPsw.id = "order_psw";
    inputOrderPsw.placeholder = "Пароль";
    inputOrderPsw.title = "Пароль";

    getPassWrap.appendChild(orderFormRow);
    getPassWrap.appendChild(orderFormRow2);
    getPassWrap.appendChild(orderFormRow3);
    getPassWrap.appendChild(orderFormRow4);
    orderFormRow.appendChild(getPswBtn);
    orderFormRow.appendChild(inputOrderPsw);

    let alertGetSmsForm =
        '<div id="alert__success__sms__form" class="alert alert-success alert-dismissible fade show el__none" role="alert">пароль отправлен на ваш номер телефона <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button></div>';
    orderFormRow2.innerHTML = alertGetSmsForm;
    orderFormRow3.appendChild(errPassText);
    orderFormRow4.appendChild(getOrderBtn);
}
if (getPassWrap != null) {
    createEnterPassRow();
}

function createEnterCaptchaRow() {
    let orderFormRow = document.createElement("div");
    let orderFormRow2 = document.createElement("div");
    let orderFormRow3 = document.createElement("div");
    let captchaWrap = document.createElement("div");
    let reloadCaptchaBtn = document.createElement("button");
    let btnIcons = document.createElement("i");
    let captchaImg = document.createElement("div");
    let inputCaptcha = document.createElement("input");
    let getPswBtn = document.createElement("button");
    let errorTextCaptcha = document.createElement("div");

    //error__text__captcha__form
    orderFormRow.className = "order__form__row";
    orderFormRow2.className = "order__form__row el__none";
    orderFormRow3.className = "order__form__row";
    orderFormRow2.id = "error__text__captcha__form";
    errorTextCaptcha.className = "text-danger";

    captchaWrap.className = "captcha__wrap";
    captchaWrap.id = "captcha__wrap";

    btnIcons.className = "fas fa-sync-alt";
    btnIcons.id = "fas111";

    reloadCaptchaBtn.className = "btn";
    reloadCaptchaBtn.type = "button";
    reloadCaptchaBtn.id = "reload__captcha__form";

    //let r11 = '{!!captcha_img('flat')!!}';

    captchaImg.className = "captcha__img";
    captchaImg.id = "captcha__img__form";
    //captchaImg.innerHTML = ('`{!!captcha_img('flat')!!}`');
    /*  $(captchaImg).append("`{!!captcha_img('flat')!!}`"); */

    inputCaptcha.className = "form-control form_input input__mw50";
    inputCaptcha.id = "captcha__form";
    inputCaptcha.type = "text";
    inputCaptcha.placeholder = "Введите число";

    getPswBtn.className = "btn btn-order_psw";
    getPswBtn.type = "button";
    getPswBtn.id = "btn__getpsw";
    getPswBtn.textContent = "Получить пароль";
    errorTextCaptcha.textContent = "Введите число с картинки";

    getPassWrap.appendChild(orderFormRow);
    orderFormRow.appendChild(captchaWrap);
    captchaWrap.appendChild(reloadCaptchaBtn);
    reloadCaptchaBtn.appendChild(btnIcons);
    captchaWrap.appendChild(captchaImg);
    orderFormRow.appendChild(inputCaptcha);
    getPassWrap.appendChild(orderFormRow2);
    getPassWrap.appendChild(orderFormRow3);
    orderFormRow2.appendChild(errorTextCaptcha);
    orderFormRow3.appendChild(getPswBtn);
    reloadCaptchaMainForm();
}
//createEnterCaptchaRow();
//добавление нужных строк в получении пароля в форме

//вешаем событие на динамически доюавленную кнопку ребут капчи
$("#get__pass__wrap").on("click", "button", function (event) {
    let thisbutton = event.target.id;
    //console.log('reload__captcha__form',thisbutton);
    if (thisbutton === "reload__captcha__form" || thisbutton === "fas111") {
        //console.log('reload__captcha__form',thisbutton);
        reloadCaptchaMainForm();
    }
    if (thisbutton === "btn__getpsw") {
        //console.log(thisbutton.id);
        $("#phone").removeClass("is-invalid input__invalid");
        $("#form__error__text__phone").addClass("el__none");
        $("#captcha__form").removeClass("is-invalid");
        $("#error__text__captcha__form").addClass("el__none");
        let phone = $("#phone").val();
        let captcha = $("#captcha__form").val();
        phone = phone.replace(/[^0-9+]/g, "");
        let id = $("#inputState :selected").val();

        if (phone === "") {
            $("#phone").addClass("is-invalid input__invalid");
            $("#form__error__text__phone").removeClass("el__none");
        } else {
            if (captcha === "") {
                $("#captcha__form").addClass("is-invalid");
                $("#error__text__captcha__form").removeClass("el__none");
                $("#error__text__captcha__form").html(
                    '<div class="text-danger">Введите число с картинки</div>'
                );
            } else {
                $.ajax({
                    type: "get",
                    url: "/addusr",
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        ),
                    },
                    data: {
                        phone: phone,
                        id: id,
                        captcha: captcha,
                    },
                    success: function (data) {
                        if (data == "captcha_err") {
                            //console.log("captcha_err")
                            $("#captcha__form").addClass("is-invalid");
                            $("#error__text__captcha__form").removeClass(
                                "el__none"
                            );
                            $("#error__text__captcha__form").html(
                                '<div class="text-danger">Неверное число с картинки</div>'
                            );
                            reloadCaptchaMainForm();
                            return;
                        }
                        //  console.log('data',data);
                        getPassWrap.innerHTML = "";

                        if (getPassWrap != null) {
                            createEnterPassRow();
                        }

                        $("#alert__success__sms__form").removeClass("el__none");
                    },
                    error: function (error) {
                        console.log("error", error);
                        //alert('Не удалось отправить код подтверждения по SMS. Нет связи с сервером');
                    },
                });
            }
        }
        let captchaNode = "#captcha__img";
        reloadCaptcha(captchaNode);
    }

    // создать заказ
    if (thisbutton === "get__order__btn") {
        event.preventDefault();
        //console.log(servArr);
        let authorized = "true";
        let calendar = $("#calendar").val();
        let comment = $("#comments").val();
        payment_type = "0";

        let minute = "0";
        let hour = "0";
        let day = "0";
        let month = "0";
        let year = "0";
        if (calendar == "") {
            //console.log('time999');
        } else {
            let calendar = $("#calendar").val();
            let calendarArray = calendar.split(" ");
            let dayArr = calendarArray[0].split(".");
            let timeArr = calendarArray[1].split(":");

            minute = timeArr[1];
            hour = timeArr[0];
            day = dayArr[0];
            month = dayArr[1];
            year = dayArr[2];
        }

        let cashless_account_id = "";
        if ($("#cashless__list").hasClass("active")) {
            cashless_account_id = document.querySelector(
                ".cashless__item[type=radio]:checked"
            ).value;
            //console.log('cashless', cashless);
        }
        let allCarOpt = document.querySelectorAll(
            ".car__opt[type='checkbox']:checked"
        );

        let car_option = [];
        for (let i = 0; i < allCarOpt.length; i++) {
            let element = allCarOpt[i];
            let carOpt = $(element).val();
            car_option.push(carOpt);
        }
        // console.log('check', allCarOpt);
        //console.log('car_option', car_option);

        let min_price = "";
        let sc = "";
        let fix_price = "";
        let pre_price = "";
        let bonuses = "";
        for (let i = 0; i < servArr.length; i++) {
            let element = servArr[i];
            //console.log('element',element[0]);

            if (element[0] == "min_price") {
                min_price = element[1];
            }
            if (element[0] == "sc") {
                sc = element[1];
            }
            if (element[0] == "fix_price") {
                fix_price = element[1];
            }
            if (element[0] == "pre_price") {
                pre_price = element[1];
                //console.log("pre_price1", pre_price);
            }
            if (element[0] == "bonuses") {
                bonuses = element[1];
            }
        }
        ajaxOrder(
            comment,
            minute,
            hour,
            day,
            month,
            year,
            car_option,
            min_price,
            pre_price,
            sc,
            fix_price,
            bonuses,
            cashless_account_id
        );
    }
    // ---создать заказ
});

$("#get__order__auth").on("click", "button", function (event) {
    let thisbutton = event.target;
    if (event.target.id === "get__order__btn") {
        event.preventDefault();
        //console.log(servArr);
        let calendar = $("#calendar").val();
        let comment = $("#comments").val();
        payment_type = "0";

        let minute = "0";
        let hour = "0";
        let day = "0";
        let month = "0";
        let year = "0";
        if (calendar == "") {
            //console.log('time999');
        } else {
            let calendar = $("#calendar").val();
            let calendarArray = calendar.split(" ");
            let dayArr = calendarArray[0].split(".");
            let timeArr = calendarArray[1].split(":");

            minute = timeArr[1];
            hour = timeArr[0];
            day = dayArr[0];
            month = dayArr[1];
            year = dayArr[2];
        }
        let cashless_account_id = "";
        if ($("#cashless__list").hasClass("active")) {
            cashless_account_id = document.querySelector(
                ".cashless__item[type=radio]:checked"
            ).value;
            //console.log('cashless', cashless);
        }

        let allCarOpt = document.querySelectorAll(
            ".car__opt[type='checkbox']:checked"
        );
        let car_option = [];
        for (let i = 0; i < allCarOpt.length; i++) {
            let element = allCarOpt[i];
            let carOpt = $(element).val();
            car_option.push(carOpt);
        }
        //console.log('check', allCarOpt);
        //console.log('car_option', car_option);

        let min_price = "";
        let sc = "";
        let fix_price = "";
        let bonuses = "";
        let pre_price = "";
        for (let i = 0; i < servArr.length; i++) {
            let element = servArr[i];
            //console.log('element',element[0]);

            if (element[0] == "min_price") {
                min_price = element[1];
            }
            if (element[0] == "sc") {
                //console.log('element[', element);
                sc = element[1];
            }
            if (element[0] == "fix_price") {
                fix_price = element[1];
            }
            if (element[0] == "pre_price") {
                pre_price = element[1];
                //console.log("pre_price", pre_price);
            }
            if (element[0] == "bonuses") {
                bonuses = element[1];
            }
        }
        ajaxOrder(
            comment,
            minute,
            hour,
            day,
            month,
            year,
            car_option,
            min_price,
            pre_price,
            sc,
            fix_price,
            bonuses,
            cashless_account_id
        );
    }
});
//получить пароль в форме заказа
$("#btn__order__getpsw").click(function () {
    getPassWrap.innerHTML = "";
    createEnterCaptchaRow();
});
//---получить пароль в форме заказа

//показать ошибку
//let divErr = document.getElementById('error__text__pass');
let divErr = document.getElementById("text__err");

function showErr(textErr, textErrClass) {
    divErr.innerHTML = "";
    divErr.innerHTML = textErr;
    if (textErrClass === "block__green") {
        $("#text__err").addClass("block__green");
        $("#text__err").removeClass("block__red");
    } else {
        $("#text__err").addClass("block__red");
        $("#text__err").removeClass("block__green");
    }
}
//---показать ошибку
// рендер меню
function menuRender() {
    $.ajax({
        type: "get",
        url: "/menu-render",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {},
        success: function (data) {
            $("#menu__block__wrapp").html(data);
        },
        error: function (error) {},
    });
}
// ---рендер меню
// создать заказ
function ajaxOrder(
    comment,
    minute,
    hour,
    day,
    month,
    year,
    car_option,
    min_price,
    pre_price,
    sc,
    fix_price,
    bonuses,
    cashless_account_id
) {
    //удаляем визуал ошибок
    $("#order_psw").removeClass("is-invalid input__invalid");
    divErr.innerHTML = "";
    // проверяем телефон
    $("#phone").removeClass("is-invalid input__invalid");
    $("#form__error__text__phone").addClass("el__none");
    let phone = $("#phone").val();
    phone = phone.replace(/[^0-9+]/g, "");
    if (phone === "") {
        $("#phone").addClass("is-invalid input__invalid");
        $("#form__error__text__phone").removeClass("el__none");
        return;
    }
    /*  if (!pre_price){
         pre_price = "";
     } */
    let orderPsw = $("#order_psw").val();

    // let phone = $('#phone').val();
    // phone = phone.replace(/[^0-9+]/g, '');
    let allAdr = document.querySelectorAll(".form_input__sortable");
    let to = [];
    let q = "";
    let lonlat = [];

    for (let index = 0; index < allAdr.length; index++) {
        const element = allAdr[index];
        if ($(element).attr("data-hiden")) {
            q = $(element).attr("data-hiden") + ", " + element.value;
        } else {
            q = element.value;
        }
        to.push(q);
        //console.log('to', to);
        lonlat.push(
            element.getAttribute("data-lon") +
                ";" +
                element.getAttribute("data-lat")
        );
    }
    let city_id = $("#inputState").val();

    $.ajax({
        type: "get",
        url: "/ajax/add_order_site",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
            login: phone,
            to: to,
            orderPsw: orderPsw,
            lonlat: lonlat,
            min_price: min_price,
            pre_price: pre_price,
            city_id: city_id,
            sc: sc,
            fix_price: fix_price,
            car_option: car_option,
            year: year,
            month: month,
            day: day,
            hour: hour,
            minute: minute,
            payment_type: payment_type,
            bonuses: bonuses,
            comment: comment,
            cashless_account_id: cashless_account_id,
            site: 1,
        },
        success: function (data) {
            //console.log('data[]', data);
            let dataPars = JSON.parse(data);
            if (dataPars["msg"]) {
                let textErr = dataPars["msg"];
                let textErrClass = "block__green";
                //let textErr = '111111';
                showErr(textErr, textErrClass);
                let route = "/current-orders";
                showOrderHistory(route);
                menuRender();

                //чистим форму
                $("#car__options__btn").removeClass(
                    "car__options__btn__active"
                );
                $("#car__options__list").removeClass(
                    "car__options__list__active"
                );
                $("#car__options__list").html("");
                let id = $("#inputState").val();
                $("#calendar").val("");
                localStorage.setItem("pr", 0);
                getCarOptionsList(id);
                let formInput = document.querySelectorAll(
                    ".form_input__sortable"
                );
                for (let index = 0; index < formInput.length; index++) {
                    const element = formInput[index];
                    element.value = "";
                }

                let inputIcon = document.querySelectorAll(".input__icon");
                if (inputIcon) {
                    for (let index = 0; index < inputIcon.length; index++) {
                        const element = inputIcon[index];
                        $(element).remove();
                    }
                }
                //console.log('formInput', formInput);
            }
            if (dataPars["error"]) {
                localStorage.setItem("pr", 0);
                //console.log('dataPars[]', dataPars['error']);
                let textErr = dataPars["error"];
                let textErrClass = "block__red";
                showErr(textErr, textErrClass);
            }
        },
        error: function (error) {
            //alert('ошибка');
            console.log("error", error);
        },
    });
}

//бургер меню
$("#burger").click(function (e) {
    $("#burger").toggleClass("burger__active");
    $("#nav__menu").toggleClass("nav__menu__active");
});

function gAdr(autocompleteList, wrapAutocomple) {
    //console.log('list', list)
}
//gAdr();
//добавим автокомплит
function adrSearch(thisInput) {
    let iconCheckCteated1 = false;
    let iconCheckCteated2 = false;

    thisInput.classList.add("autocomplete-input");
    let wrap = thisInput.parentNode;
    wrap.classList.add("autocomplete-wrap");
    let list = document.createElement("div");
    list.className = "autocomplete-list";
    let checkWrap = wrap.childNodes;

    //проверка существует ли уже autocomplete-list
    let checkList = "false";
    for (let index = 0; index < checkWrap.length; index++) {
        let element = checkWrap[index];
        if (element.className === "autocomplete-list") {
            checkList = "true";
            break;
        }
    }
    if (checkList === "false") {
        wrap.appendChild(list);
    }

    let autocompleteList = wrap.querySelector(".autocomplete-list");
    let wrapAutocomple = autocompleteList.parentNode;
    let listItems = [];
    let focusedItem = -1;
    //----
    function setActive(active = true) {
        if (active) wrap.classList.add("active");
        else wrap.classList.remove("active");
    }

    function addIconCheck(colorIconsCheck, typeIconsCheck) {
        let iconCheck = document.createElement("i");
        iconCheck.className =
            typeIconsCheck +
            " " +
            "fa-check-circle" +
            " " +
            "input__icon" +
            " " +
            colorIconsCheck;
        wrap.append(iconCheck);
    }

    function unfocusAllItems() {
        listItems.forEach((item) => {
            if (item.localName === "div") {
                item.classList.remove("focused");
            }
        });
    }

    function listItemArr(listItemsAll) {
        listItems = [];
        for (let index = 0; index < listItemsAll.length; index++) {
            if (listItemsAll[index].className === "autocomplete-item") {
                listItems.push(listItemsAll[index]);
            }
        }
        return listItems;
    }

    function focusItem(index) {
        //console.log('focusItem',index);
        if (!listItems.length) return false;
        if (index > listItems.length - 1) return focusItem(0);
        if (index < 0) return focusItem(listItems.length - 1);
        focusedItem = index;
        unfocusAllItems();
        listItems[focusedItem].classList.add("focused");
    }

    function selectItem(index) {
        //console.log('selectItem',index);
        if (!listItems[index]) return false;
        let thisListItems = listItems[index].querySelector(".search__addres");
        thisInput.value = thisListItems.innerText.trim();
        $(thisInput).attr("data-lon", listItems[index].dataset.lon);
        $(thisInput).attr("data-lat", listItems[index].dataset.lat);
        $(thisInput).attr("data-check", listItems[index].dataset.check);
        $(thisInput).attr("data-check2", listItems[index].dataset.check2);
        $(thisInput).attr("data-hiden", listItems[index].dataset.hiden);
        //console.log(listItems[index]);
        let check = listItems[index].dataset.check;

        list.innerHTML = "";

        //console.log('listItems[index]', listItems[index].lat);
        // добовляем иконку
        let inputParent = thisInput.closest(".sortable__row");
        let thisInputIcon1 = inputParent.querySelector(".input__icon__1");
        let thisInputIcon2 = inputParent.querySelector(".input__icon__2");
        if (check != null) {
            if (check == 1) {
                if (!iconCheckCteated1) {
                    $(thisInputIcon2).detach();
                    $(thisInputIcon1).detach();
                    iconCheckCteated2 = false;
                    let colorIconsCheck = "input__icon__1";
                    let typeIconsCheck = "fas";
                    addIconCheck(colorIconsCheck, typeIconsCheck);
                    iconCheckCteated1 = true;
                }
                setActive(false);
            }
            if (check == 2) {
                if (!iconCheckCteated2) {
                    $(thisInputIcon1).detach();
                    $(thisInputIcon2).detach();
                    iconCheckCteated1 = false;
                    let colorIconsCheck = "input__icon__2";
                    let typeIconsCheck = "far";
                    addIconCheck(colorIconsCheck, typeIconsCheck);
                    iconCheckCteated2 = true;
                }
                thisInput.value = thisInput.value.trim() + " ";
                mainSearch();
                setActive(true);
            }
            /* $(thisInput).trigger('focus'); */
        }
        /* $(input).trigger('focus');
        $(input).trigger("input").val(function(i,val){return val + ', ';}); */
        getDistanceAjax();
    }

    function mainSearch() {
        let q = "";
        let inputParent = thisInput.closest(".sortable__row");
        let thisInputIcon1 = inputParent.querySelector(".input__icon__1");
        let thisInputIcon2 = inputParent.querySelector(".input__icon__2");

        $(thisInputIcon1).detach();
        iconCheckCteated1 = false;
        //$(thisInputIcon2).detach();
        iconCheckCteated2 = false;
        let showPriceDist = document.getElementById("show__price__dist");
        let showPriceWrap = document.getElementById("show__price__wrap");
        let showPriceRur = document.getElementById("show__price__rur");
        let showPrice = document.getElementById("show__price");
        showPriceDist.innerHTML = "";
        showPriceRur.innerHTML = "";
        $(showPrice).removeClass("order__form__row show__price");
        $(showPriceWrap).removeClass("show__price__wrap");
        //$(thisInput).attr("data-hiden");

        let value = thisInput.value;
        if (!value) {
            $(thisInput).attr("data-hiden", "");
            return setActive(false);
        }

        if ($(thisInput).attr("data-hiden")) {
            q = $(thisInput).attr("data-hiden") + "," + value;
        } else {
            q = value;
        }
        let id = $("#inputState :selected").val();
        list.innerHTML = "";
        listItems = [];
        /*  let q = 'лени';
         let id = '1'; */
        $.ajax({
            type: "get",
            url: "/adres-search",
            async: false,
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                id: id,
                q: q,
            },
            success: function (data) {
                //console.log('mainSearch-data', data)
                list.innerHTML = "";
                list.innerHTML = data;
                wrap.classList.add("active");
                let listItemsAll = list.childNodes;
                listItems = listItemArr(listItemsAll);
                focusItem(0);
            },
            error: function (error) {
                console.log(error);
            },
        });
    }
    /*   listItemsAll[index].addEventListener('click', function () {
          selectItem(listItems.indexOf(item));
      }); */
    $(list).on("click", function (event) {
        selectItem(listItems.indexOf(event.target.parentNode));
    });

    /*     thisInput.addEventListener('keydown', e => {
            let keyCode = e.keyCode;
            if (keyCode === 40) { // arrow down
                e.preventDefault();
                focusedItem++;
                focusItem(focusedItem);
            } else if (keyCode === 38) { //arrow up
                e.preventDefault();
                if (focusedItem > 0) focusedItem--;
                focusItem(focusedItem);
            } else if (keyCode === 27) { // escape
                setActive(false);
            } else if (keyCode === 13) { // enter
             console.log('keyCode === 13', focusedItem);
                selectItem(focusedItem);
            } else if (keyCode >= 65 && keyCode <= 90) { // enter
                //console.log('keyCode >= 65 && keyCode <= 90');
            } else if (keyCode >= 48 && keyCode <= 57) { // enter
                //console.log('keyCode >= 48 && keyCode <= 57');
            }
        }); */
    document.body.addEventListener("click", function (e) {
        if (!e.target.closest(".autocomplete-item")) {
            setActive(false);
        }
    });
    if ($(thisInput).hasClass("active-s")) {
        mainSearch();
    } else {
        $(thisInput).addClass("active-s");
        thisInput.addEventListener("keydown", (e) => {
            let keyCode = e.keyCode;
            if (keyCode === 40) {
                // arrow down
                e.preventDefault();
                focusedItem++;
                focusItem(focusedItem);
            } else if (keyCode === 38) {
                //arrow up
                e.preventDefault();
                if (focusedItem > 0) focusedItem--;
                focusItem(focusedItem);
            } else if (keyCode === 27) {
                // escape
                setActive(false);
            } else if (keyCode === 13) {
                // enter
                console.log("keyCode === 13", focusedItem);
                selectItem(focusedItem);
            } else if (keyCode >= 65 && keyCode <= 90) {
                // enter
                //console.log('keyCode >= 65 && keyCode <= 90');
            } else if (keyCode >= 48 && keyCode <= 57) {
                // enter
                //console.log('keyCode >= 48 && keyCode <= 57');
            }
        });
        thisInput.addEventListener("input", (event) => {
            mainSearch();
        });
    }
} //adrSearch
$("#sortable__wrap").on("focus", "input", function (event) {
    let thisInput = event.target;
    adrSearch(thisInput);
});
//таймер
let timerItem = "";
let checkTimer = "";
//дата в футер
document.getElementById("this__year").innerHTML = new Date().getFullYear();
//отслеживаем url
let urlPage = window.location.hash;
let linkWrap = document.getElementById("nav__menu");
let linkPage = linkWrap.getElementsByTagName("a");
//проверяем линк при загрузке страницы
//console.log('Location.hash', location.hash)
for (let index = 0; index < linkPage.length; index++) {
    const element = linkPage[index];
    if (location.href === element.href) {
        if (element.id === "current__orders") {
            showOrderHistory("/current-orders");
        } else if (element.id === "order__history") {
            showOrderHistory("/order-history");
        } else {
            showPage("/page", element.getAttribute("pid"));
        }
    }
}
let politElem = document.getElementById("politika_konfidencialnosti");
if (politElem && politElem.href === location.href) {
    showPage("/page", politElem.getAttribute("pid"));
}
if (location.hash === "#politics-mobile") {
    showPage("/page", 6);
}
window.addEventListener("hashchange", () => {
    if (location.hash === "#politics-mobile") {
        showPage("/page", 6);
    }
});
/* if (location.href === location.protocol + '//'+location.host + '/'){
    console.log('!!!!!!!!!!!!!!!!!!!!!')
    location.hash = '';
} */
if (location.hash === "") {
    showPage("/page", 1);
}
$.ajax({
    type: "GET",
    url: "/ajax/get_cities",
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
    /*     data: {
            id: id
        }, */
    error: function (jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
    },
    success: function (data) {},
});
//console.log('screen.width', screen.width)
//корпоративные поездки

function checkCashless() {
    //console.log('checkCashless')
    let id = $("#inputState :selected").val();
    if (id) {
        $.ajax({
            type: "get",
            url: "/get_cashless_accounts",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                id: id,
            },
            success: function (data) {
                if (data) {
                    let dataPars = JSON.parse(data);
                    if (dataPars["error"]) {
                        //console.log(dataPars);
                        return;
                    }
                }
            },
            error: function (error) {
                console.log(error);
            },
        });
    }
}
checkCashless();

function getCashlessList() {
    $.ajax({
        type: "get",
        url: "/cashless-list",
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function (data) {
            //console.log('cashless__list', data);
            $("#cashless__list").html(data);
        },
        error: function (error) {
            console.log(error);
        },
    });
}
//отслеживаем нажатия
$("#check__wrapp").click(function (e) {
    if (e.target.id == "customSwitch1") {
        $("#check__text").addClass("active");
        e.target.checked = false;
    }
    if (e.target.id == "customSwitch2") {
        $("#cashless__list").toggleClass("active");
        if ($("#cashless__list").hasClass("active")) {
            getCashlessList();
        }
    }
    if (e.target.id == "check__login") {
        $("#popup__login").addClass("popup__active");
    }
});

function addPriseCarOpt() {
    let price = 0;
    let priceWithOpt = 0;
    let pr_type = null;
    if (localStorage.getItem("pr")) {
        price = localStorage.getItem("pr");
    }
    if (localStorage.getItem("pr_type")) {
        pr_type = localStorage.getItem("pr_type");
    }
    let allCarOpt = document.querySelectorAll(
        ".car__opt[type='checkbox']:checked"
    );
    for (let i = 0; i < allCarOpt.length; i++) {
        let element = allCarOpt[i];
        pp = element.getAttribute("pp");
        pf = element.getAttribute("pf");
        priceWithOpt += Math.round(
            Number(pf) + (Number(price) / 100) * Number(pp)
        );
    }
    let sum = Number(priceWithOpt) + Number(price);
    if (pr_type == "fix_price") {
        $("#show__price__rur").html(sum + "&#8381;");
    } else {
        $("#show__price__rur").html("Поездка от " + sum + "&#8381;");
    }
}
$("#car__options__list").on("click", "input", function (e) {
    if (e.target.className == "form-check-input car__opt") {
        if ($("#show__price__rur").html()) {
            addPriseCarOpt();
        }
    }
});
